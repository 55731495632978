<template>
    <v-dialog v-model="dialog" width="80%" class="ma-auto">
      <template v-slot:activator="{on,attrs}">
        <v-btn
          icon
          dark
          v-bind="attrs"
          v-on="on"
          @click.stop="start()"
        >   
          <v-icon
            small
            class="mr-2"
            color="grey"
          >
            mdi-cog
          </v-icon>
        </v-btn>
      </template>
      <v-card >
        <v-card-title>
          <v-row>
            <v-col>
             Inventory Id: {{currentItem.id}} - Hostname: {{currentItem.hostname }} atualizada em: {{new Date(currentItem.data).toLocaleString() }}
            </v-col>
            <v-col class="text-right">
              <v-btn icon @click="dialog=false"><v-icon>mdi-close</v-icon></v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <h1>Empresa</h1>
          <v-container>
            <v-row dense>
              <v-col>
                <v-select
                  v-model="currentItem.empresa"
                  :items="empresas"
                  :loading="!valida('empresas')"
                  v-show="valida('empresas')"
                  @change="get_lists(`inventario/unidades/?empresa=${currentItem.empresa.id}`, 'unidades')"
                  item-value="id"
                  item-text="nome"
                  label="Selecione Empresa"
                  return-object
                ></v-select>
              </v-col>
              <v-col cols="1">

              </v-col>
            </v-row>
             <v-row dense>
              <v-col>
                <v-select
                  v-model="currentItem.unidade"
                  :items="unidades"
                  :loading="!valida('unidades')"
                  v-show="valida('unidades')"
                  @change="get_lists(`inventario/setores/?unidade=${currentItem.unidade.id}`, 'setores')"
                  item-value="id"
                  item-text="nome"
                  label="Selecione Unidade"
                  return-object
                ></v-select>
              </v-col>
              <v-col cols="1">

              </v-col>
            </v-row>
             <v-row dense>
              <v-col>
                <v-select
                  v-model="currentItem.setor"
                  :items="setores"
                  :loading="!valida('setores')"
                  v-show="valida('setores')"
                  @change="get_lists(`inventario/locais/?setor=${currentItem.setor.id}`, 'locais')"
                  item-value="id"
                  item-text="nome"
                  label="Selecione Setor"
                  return-object
                ></v-select>
              </v-col>
              <v-col cols="1">

              </v-col>
            </v-row>
             <v-row dense>
              <v-col>
                {{ currentItem }}
                <v-select
                  v-model="currentItem.local"
                  :items="locais"
                  :loading="!valida('locais')"
                  v-show="valida('locais')"
                  @change="get_lists(`inventario/jornadas/?local=${currentItem.local.id}`, 'jornadas')"
                  item-value="id"
                  item-text="nome"
                  label="Selecione Locais"
                  return-object
                ></v-select>
              </v-col>
              <v-col cols="1">

              </v-col>
            </v-row> 
          </v-container>
        </v-card-text>
        <v-card-actions><v-btn outlined color="green" @click="save"><v-icon>md-content-save</v-icon>salvar</v-btn></v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  <script>
  import api from '@/http'
  export default {
    name:"Edit-inventory",
    props:{
      currentItem:{
        type:Object
      }
    },data:()=>({
      dialog:false,
      loading:false,
      empresas:[],
      unidades:[],
      unidade: {},
      jornadas:[],
      jornada: {},
      setores:[],
      setor: {},
      locais:[],
      local: {}
    }),
    computed:{
      empresa: {
        get() {
          var obj = {id:null, nome:null};
          if (this.currentItem.empresa !== null) {
            obj = this.currentItem.empresa;
          }
          return obj;
        },
        set(value) {
          // Defina aqui o que você deseja fazer com o valor atribuído
          // Por exemplo, atualizar a propriedade `currentItem.local.unidade.empresa`
          if (this.currentItem.empresa !== null) {
            this.currentItem.empresa = value;
          }
        }
      },
     
    },
    methods:{
      get_local(locais){
        if(locais.local !== undefined){
          this.locais = locais.local
        }
      },
      get_lists(url,lista) {
        this.loading = true
        api.get(url)
        .then(res => {
          this[lista] = res.data
        })
        .finally(() => {
          this.loading = false
        })
      },
      start(){
        this.get_lists('inventario/empresas/', 'empresas')
      },
      valida(nome){
        return this[nome]!==undefined?this[nome].length:0  
      },
      save(){
        api.put(`inventario/maquina/${this.currentItem.id}/`, this.currentItem) 
        .then((res)=>{console.log(res)})
        .catch((error)=>{console.log(error)}) 
        .finally(()=>{console.log('finally')}) 
      }
    },
    
  }
  </script>
